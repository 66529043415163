import request from '@/core/services/api.services.request'

export function getAllproducts(params) {
  return request({
    url: '/product/list',
    method: 'get',
    params: params
  })
}

export function getProduct(product_id) {
  return request({
    url: '/product/view/'+product_id,
    method: 'get',
  })
}


export function getSimilarProduct(sku) {
  return request({
    url: 'similar/product/view/'+sku,
    method: 'get',
  })
}

export function deleteProduct(product_id) {
  return request({
    url: '/product/delete/'+product_id,
    method: 'delete',
  })
}

export function getAllcategory(params) {
  return request({
    url: '/product/categories',
    method: 'get',
    params: params
  })
}

export function getAllcategoryList() {
  return request({
    url: '/product/categorieslist',
    method: 'get'
  })
}

export function getProductCategory(){
  return request({
    url: '/product/category/list',
    method: 'get'
  })
}

export function getCategory(cat_id) {
  return request({
    url: '/product/category/'+cat_id,
    method: 'get',
  })
}

export function deleteCategory(cat_id) {
  return request({
    url: '/product/delete-category/'+cat_id,
    method: 'delete',
  })
}


export function getAllBrands(params) {
  return request({
    url: '/product/brands',
    method: 'get',
    params: params
  })
}

export function getAllBrandsList() {
  return request({
    url: '/product/brandlist',
    method: 'get'
  })
}


export function getBrand(brand_id) {
  return request({
    url: '/product/brand/'+brand_id,
    method: 'get',
  })
}

export function deleteBrand(brand_id) {
  return request({
    url: '/product/delete-brand/'+brand_id,
    method: 'delete',
  })
}

export function changeStatus(product_id, status) {
  return request({
    url: '/product/change/status/' + product_id + '/' + status,
    method: 'post',
  })
}


export function categoryStatus(cat_id, status) {
  return request({
    url: '/product/change/status/' + cat_id + '/' + status,
    method: 'post',
  })
}

export function getProductsForDropdown() {
  return request({
    url: '/product/list/all',
    method: 'get'
  })
}

export function getAllProductTypes() {
  return request({
    url: '/product/type/list',
    method: 'get',
  })
}

export function getTaxName() {
  return request({
    url: '/tax/nameList',
    method: 'get',
  })
}
export function changeCategoryStatus(cat_id, status){
  return request({
    url: '/product/category/status/' + cat_id + '/' + status,
    method: 'post',
  })
}

export function getProductPrice() {
  return request({
    url: '/product/priceList',
    method: 'get',
  })
}

export function getCategoryTree() {
  return request({
    url: '/product/categoriesTree',
    method: 'get',
  })
}

export function searchProducts(q) {
  return request({
    url: '/product/search/'+q,
    method: 'get',
  })
}

export function deleteImage(name) {
  return request({
    url: '/product/image/deleted/'+name,
    method: 'delete',
  })
}

export function getImportProduct() {
  return request({
    url: '/product/import',
    method: 'get',
  })
}

export function downloadSampleCsv() {
  return request({
    url: '/sample/product_csv',
    method: 'get',
  })
}

export function downloadSuccessImport(prefix) {
  return request({
    url: '/download/import_success/'+prefix,
    method: 'get',
  })
}

export function downloadErrorImport(prefix) {
  return request({
    url: '/download/import_error/'+prefix,
    method: 'get',
  })
}

export function getPosProductID() {
  return request({
    url: '/pos/product/last_id',
    method: 'get',
  })
}

export function getProductID() {
  return request({
    url: '/product/last_id',
    method: 'get',
  })
}

export function createProductId() {
  return request({
    url: '/create/produId',
    method: 'get',
  })
}

export function getByIDProductId(orderType) {
  return request({
    url: "/create/produId/" + orderType,
    method: "get",
  });
}

export function createPosProductId(category) {
  return request({
    url: '/create/posproduId/'+category,
    method: 'get',
  })
}

export function getProductUnitType() {
  return request({
    url: 'product/unit/list',
    method: 'get',
  })
}
export function getAllTemporaryProducts(params) {
  return request({
    url: '/temporaryproduct/list',
    method: 'get',
    params: params
  })
}
export function moveTempProduct(product_id) {
  return request({
    url: '/temporaryproduct/moveTempProduct/'+product_id,
    method: 'post',
  })
}
export function deleteTempProduct(product_id) {
  return request({
    url: 'temporary/product/delete/'+product_id,
    method: 'delete',
  })
}

export function getAllPosProducts(params) {
  return request({
    url: '/posproduct/list',
    method: 'get',
    params: params
  })
}
export function movePosProduct(product_id) {
  return request({
    url: '/posproduct/movePosProduct/'+product_id,
    method: 'post',
  })
}
export function deletePosProduct(product_id) {
  return request({
    url: 'pos/product/delete/'+product_id,
    method: 'delete',
  })
}

export function getSupplierSkuCheck(supplier_sku,product_id) {
  return request({
    url: '/supplier/checksku/'+supplier_sku+'/'+product_id,
    method: 'get',
  })
}

export function getSupplierUpcCheck(supplier_upc,product_id) {
  return request({
    url: '/supplier/checkupc/'+supplier_upc+'/'+product_id,
    method: 'get',
  })
}
export function getSupplierMfgCheck(supplier_mfg,product_id) {
  return request({
    url: '/supplier/checkMfg/'+supplier_mfg+'/'+product_id,
    method: 'get',
  })
}

export function moveTempProductList(params) {
  return request({
    url: '/move/temporaryproduct/list',
    method: 'get',
    params: params
  })
}

export function getDeleteproducts(params) {
  return request({
    url: '/delete/product/list',
    method: 'get',
    params: params
  })
}

export function getProductImage(sku) {
  return request({
    url: '/product/image/'+sku,
    method: 'get',
  })
}
export function getAllexcelproducts(params) {
  return request({
    url: '/excel/product/list',
    method: 'get',
    params: params
  })
}

export function getProductCatgoryCount(category_id) {
  return request({
    url: '/product/categoryCount/'+category_id,
    method: 'get',
  })
}

