import request from '@/core/services/api.services.request'

export function getAllproducts(params) {
  return request({
    url: '/invproduct/list',
    method: 'get',
    params: params
  })
}

export function getDropdown(params) {
  return request({
    url: '/invproduct/dropdownlist',
    method: 'get',
    params: params
  })
}
export function getProduct(product_id) {
  return request({
    url: '/invproduct/view/'+product_id,
    method: 'get',
  })
}

export function deleteProduct(product_id) {
    return request({
      url: '/invproduct/delete/'+product_id,
      method: 'delete',
    })
  }

  export function changeStatus(product_id, status) {
    return request({
      url: '/product/change/status/' + product_id + '/' + status,
      method: 'post',
    })
  }

  export function getNotification() {
    return request({
      url: '/notification/list',
      method: 'get',
    })
  }

  export function showNotification() {
    return request({
      url: '/notification/read',
      method: 'get',
    })
  }

  export function getAllnotification(params) {
    return request({
      url: '/notification/listall',
      method: 'get',
      params: params
    })
  }
  
  export function getlowstock(params) {
    return request({
      url: '/lowstock/list',
      method: 'get',
      params: params
    })
  }

  export function getoverStock(params) {
    return request({
      url: '/overStock/list',
      method: 'get',
      params: params
    })
  }

  export function updateProduct(product_id) {
    return request({
      url: '/invproduct/edit/'+ product_id ,
      method: 'post'     
    })
  }

  export function viewLowstock(product_id) {
    return request({
      url: '/invproduct/lowStockview/'+ product_id ,
      method: 'get'     
    })
  }

  export function getCLearanceProduct(params) {
    return request({
      url: '/clearanceProduct/list',
      method: 'get',
      params: params
    })
  }