import axios from 'axios'
import JwtService from "@/core/services/jwt.service";
import router from '@/router'

const service = axios.create({
  baseURL:process.env.VUE_APP_BASE_API_URL,
  timeout: 30000
});

service.interceptors.request.use(config => {
  if (JwtService.getToken()) {
    config.headers['Authorization'] = 'Bearer '+JwtService.getToken();
  }
  return config
}, error => {

  return Promise.reject(error)
});

service.interceptors.response.use(response => response, error => {
   const response = error.response;
    if (response && response.status === 401) { // && error.response.data.error == "token_expired"
      JwtService.destroyToken();
      router.push({ name: 'login' })
    }else if(!error.status){
      //alert('Internet connection failed.')
    }
    
    return Promise.reject(error.response)
  });

export default service
